// lodash
import _ from 'lodash';

// moment.js
import moment from 'moment/moment';
import 'moment/locale/de';

// jQuery
import $ from 'jquery';

// Popper
import Popper from '@popperjs/core/dist/umd/popper.js';

// tippy.js
import tippy, { delegate } from 'tippy.js';

// Sockets
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Livewire
import {Alpine, Livewire} from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import Tooltip from '@ryangjchandler/alpine-tooltip';
import AlpineLazyLoadAssets from 'alpine-lazy-load-assets';
import '@nextapps-be/livewire-sortablejs';
import {livewire_hot_reload} from 'virtual:livewire-hot-reload';

// Flatpickr
import flatpickr from 'flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/l10n/default';
import 'flatpickr/dist/l10n/de';

// AirDatepicker
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import localeDe from 'air-datepicker/locale/de';
import localeFr from 'air-datepicker/locale/fr';
import localeAr from 'air-datepicker/locale/ar';

// Tempus Dominus
import {DateTime, loadLocale, locale, Namespace, TempusDominus} from '@eonasdan/tempus-dominus';
import {localizationDe, nameDe} from '@eonasdan/tempus-dominus/dist/locales/de';
import {localizationFr, nameFr} from '@eonasdan/tempus-dominus/dist/locales/fr';
import {localizationAr, nameAr} from '@eonasdan/tempus-dominus/dist/locales/ar';

// Powergrid
import './../../vendor/power-components/livewire-powergrid/dist/powergrid';
import './../../vendor/power-components/livewire-powergrid/dist/powergrid.css';

// SweetAlerts2
import Swal from 'sweetalert2';

// Leaflet
import L from 'leaflet/dist/leaflet';

// Print.js
import printJS from "print-js-updated";

// Slim Select
import SlimSelect from 'slim-select'
window.SlimSelect = SlimSelect

// Lightbox 2
import lightbox from '../vendor/lightbox2/src/js/lightbox.cjs';
window.lightbox = lightbox;

// lodash
window._ = _;

// Popper
window.Popper = Popper;

// moment
moment.locale('de');
window.moment = moment;

// jQuery
window.jQuery = window.$ = $;

// tippy
tippy.delegate = delegate;
window.tippy = tippy;

// Sockets
window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
});

// Alpine
Alpine.plugin(collapse)
Alpine.plugin(Tooltip);
Alpine.plugin(AlpineLazyLoadAssets);

// Livewire
livewire_hot_reload();
Livewire.start();

// Flatpickr
window.FlatPickr = flatpickr;
window.rangePlugin = rangePlugin;
window.monthSelect = monthSelect;

// AirDatepicker
window.AirDatepicker = AirDatepicker;
window.AirDatepicker.Locale = {};
window.AirDatepicker.Locale.En = localeEn;
window.AirDatepicker.Locale.De = localeDe;
window.AirDatepicker.Locale.Fr = localeFr;
window.AirDatepicker.Locale.Ar = localeAr;

// Tempus Dominus
window.TempusDominus = TempusDominus;
window.TempusDominus.defaultLocale = nameDe;
window.TempusDominus.Namespace = Namespace;
window.TempusDominus.DateTime = DateTime;

loadLocale({localizationDe, nameDe});
loadLocale({localizationFr, nameFr});
loadLocale({localizationAr, nameAr});
locale(nameDe);

// Leaflet
L.Icon.Default.imagePath = '/assets/leaflet/';

// other imports
window.printJS = printJS;
window.Swal = Swal;
